
import { useMarketStore } from '@/stores/market'

export default {
  name: 'MPPopularNow',
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  data() {
    const navigation = {
      prevEl: '.MPPopularNow-Navigation-Prev',
      nextEL: '.MPPopularNow-Navigation-Next',
    }
    const pagination = {
      el: '.MPPopularNow-Pagination',
      clickable: true,
    }
    return {
      keenSliderOptions: {
        slides: {
          perView: 2,
          spacing: 8,
        },
        breakpoints: {
          '(min-width: 400px)': {
            slides: { perView: 2, spacing: 5 },
          },
          '(min-width: 768px)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(min-width: 1000px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(min-width: 1200px)': {
            slides: { perView: 5, spacing: 8 },
          },
        },
      },
    }
  },
  computed: {
    isShowingPopularNow() {
      return this.marketStore?.PopularNowProducts
    },
    showComponent() {
      return this.isShowingPopularNow?.length
    },
  },
  watch: {
    showComponent(val) {
      this.$nextTick(() => {
        if (val) {
          setTimeout(() => {
            this.$refs?.MPPopularNowSlider?.updateSlider()
          }, 100)
        }
      })
    },
  },
}
